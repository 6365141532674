export const environment = {
  apiParq: 'https://api-qa.parqco.com/api',
  clients: {
    operator: 'https://main.d2aol8f6bio8ep.amplifyapp.com',
  },
  email: '@users.parqco.com',
  kushkiMerchantId: '1000000681457096980515794014113',
  production: true,
  parq: {
    anprEmailPrefix: 'anpr.',
    driverId: 760,
    guestsDomain: '@guests.parqco.com',
    serviceChargePercentage: 5,
  },
  microliths: {
    entities: 'https://api-qa.parqco.com/api/entities/v2',
    management: 'https://api-qa.parqco.com/api/management/v2',
    payments: 'https://api-qa.parqco.com/api/payments/v2',
    services: 'https://api-qa.parqco.com/api/services/v2',
    users: 'https://api-qa.parqco.com/api/users/v2',
  },
};
